import { INotification, NotificationType } from '../models/INotification';
import { useCallback, useMemo } from 'react';
import { axiosServices } from '../utils/axios';
import { setOuterAppointment } from '../store/slices/outerAppointmentSlice';
import { openConfirmPopup } from '../store/confirmPopupSlice';
import { useAppDispatch } from './redux';
import { useNavigate } from 'react-router-dom';
import notificationAPI from '../services/NotificationService';

import MessageIcon from '@material-ui/icons/Message';
import EventNoteIcon from '@material-ui/icons/EventNote';
import PersonIcon from '@material-ui/icons/Person';
import CancelIcon from '@material-ui/icons/Cancel';

const notificationsVariants = {
    [NotificationType.appointmentCanceled]: {
        title: `Запись отменена`,
        icon: <CancelIcon />
    },
    [NotificationType.appointmentDateUpdated]: {
        title: `Запись изменена`,
        icon: <EventNoteIcon />
    },
    [NotificationType.appointmentCreated]: {
        title: `Запись создана`,
        icon: <EventNoteIcon />
    },
    [NotificationType.appointmentCompleted]: {
        title: `Запись завершена`,
        icon: <EventNoteIcon />
    },
    [NotificationType.appointmentCheckedIn]: {
        title: `Клиент прибыл`,
        icon: <PersonIcon />
    },
    [NotificationType.appointmentMessage]: {
        title: `Новое сообщение`,
        icon: <MessageIcon />
    }
} as const;

const useNotificationItem = (notification: INotification | null) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [readNotification] = notificationAPI.useMarkNotificationAsReadMutation();

    const notificationCustomer = notification?.data.customer;
    const customerName = useMemo(() => {
        if (notificationCustomer) {
            const { firstname, lastname } = notificationCustomer;
            return [firstname, lastname].filter((s) => !!s).join(' ');
        }

        return '';
    }, [notificationCustomer]);

    const notificationType = notification?.type;
    const notificationTitle = notification?.title ?? '';
    const notificationData = useMemo(
        () =>
            notificationType && notificationsVariants[notificationType]
                ? notificationsVariants[notificationType]
                : {
                      title: notificationTitle,
                      icon: null
                  },
        [notificationType, notificationTitle]
    );

    const isMessage = useMemo(() => notificationType === NotificationType.appointmentMessage, [notificationType]);

    const dataId = notification?.data.id;
    const notificationId = notification?.id;

    const handleClickNotification = useCallback(async () => {
        if (dataId && notificationId) {
            try {
                const res = await axiosServices.get(`/appointments/${dataId}`);
                if (res.data) {
                    dispatch(setOuterAppointment(res.data));
                    navigate(isMessage ? '/calendar?tab=messages' : '/calendar', { replace: true });
                }
                readNotification(notificationId);
            } catch (err) {
                if (err.message === 'Запись не найдена') {
                    dispatch(
                        openConfirmPopup({
                            text: 'Данная запись была удалена и не может быть открыта.'
                        })
                    );
                    readNotification(notificationId);
                } else {
                    dispatch(
                        openConfirmPopup({
                            confirmText: `Ok`,
                            text: 'Произошла ошибка. Попробуйте позже.'
                        })
                    );
                }
            }
        }
    }, [dataId, dispatch, isMessage, navigate, notificationId, readNotification]);

    return { customerName, notificationData, isMessage, handleClickNotification };
};

export default useNotificationItem;
