import MainCard from '../../ui-component/cards/MainCard';
import { Box, Button, Stack } from '@mui/material';
import useAuth from '../../hooks/useAuth';
import { useCallback, useMemo } from 'react';
import moment from 'moment';
import userAPI from '../../services/AccountService';
import useShowSnackbar from '../../hooks/useShowSnackbar';
import { SnackBarTypes } from '../../store/snackbarReducer';
import { useNavigate } from 'react-router-dom';

const SubscriptionsManagement = () => {
    const { user, checkAuthentication } = useAuth();
    const { showSnackbar } = useShowSnackbar();
    const navigate = useNavigate();

    const [unsubscribe] = userAPI.useDeletePaymentMethodMutation();

    const nextPayment = user?.companyOwner?.subscription_next_payment_at;
    const nextPaymentDate = useMemo(() => {
        if (nextPayment) {
            return moment(nextPayment).format('DD MMMM YYYY, HH:mm');
        }
        return null;
    }, [nextPayment]);

    const handleUnsub = useCallback(() => {
        unsubscribe(null)
            .unwrap()
            .then((res) => {
                showSnackbar({
                    alertSeverity: SnackBarTypes.Success,
                    message: 'Платежный метод успешно удален'
                });
                checkAuthentication();
                navigate('/');
            });
    }, [checkAuthentication, navigate, showSnackbar, unsubscribe]);

    return (
        <MainCard title="Управление подпиской">
            <Stack spacing={2}>
                <Box>{`Активная подписка: ${user?.companyOwner?.subscription_name}`}</Box>
                <Box>{`Дата следующего списания: ${nextPaymentDate}`} </Box>
                {user?.payment_method ? (
                    <Box>
                        <Box display="inline" margin="0 20px 0 0">{`Привязанный способ оплаты: ${user?.payment_method?.title}`}</Box>
                        <Button
                            onClick={handleUnsub}
                            color="error"
                            variant="contained"
                            sx={{ alignSelf: 'flex-start' }}
                            disableElevation
                            size="small"
                        >
                            Удалить
                        </Button>
                    </Box>
                ) : null}
            </Stack>
        </MainCard>
    );
};

export default SubscriptionsManagement;
