// material-ui
import { Button } from '@material-ui/core';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import { useNavigate, useParams } from 'react-router';
import React, { useCallback, useContext, useEffect, useState } from 'react';

// assets
import ChevronLeftOutlinedIcon from '@material-ui/icons/ChevronLeftOutlined';
import serviceAPI from 'services/ServiceService';
import { IService } from 'models/IService';
import ServiceForm from './ServiceForm';
import { min_service_reschedule_interval } from '../../store/constant';
import { AbilityContext } from '../../utils/roles/Can';
import useMobileCreateButton from '../../hooks/useMobileCreateButton';
import useInvalidateServices from './use-invalidate-services';
import useShowSnackbar from '../../hooks/useShowSnackbar';
import { SnackBarTypes } from '../../store/snackbarReducer';
import ServiceSurvey, { SurveyData } from './ServiceSurvey';
import { Box, Tab, Tabs } from '@mui/material';
import TabPanel from '../../ui-component/TabPanel';

const ServiceEdit: React.FC = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const invalidate = useInvalidateServices();
    const ability = useContext(AbilityContext);

    const [value, setValue] = useState(0);
    const handleTabsChange = (_event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    useMobileCreateButton({
        action: () => navigate('/service/create'),
        condition: ability.can('create', 'service')
    });

    // @ts-ignore
    const { data, isFetching } = serviceAPI.useGetServiceQuery(id, {
        refetchOnMountOrArgChange: true
    });
    const [updateService] = serviceAPI.useUpdateServiceMutation();
    const [updateQuestions] = serviceAPI.useUpdateServiceQuestionsMutation();

    const { showSnackbar } = useShowSnackbar();

    const handleUpdate = (service: IService) => {
        if (!service.rescheduling_interval) service.rescheduling_interval = min_service_reschedule_interval;

        updateService(service)
            .unwrap()
            .then(() => {
                showSnackbar({
                    message: 'Услуга обновлена',
                    alertSeverity: SnackBarTypes.Success
                });
                navigate('/service', { replace: true });
                invalidate();
            })
            .catch((e) => {
                showSnackbar({
                    message: e.data || 'Ошибка: услуга не обновлена',
                    alertSeverity: SnackBarTypes.Error
                });
            });
    };

    const onUpdateSurvey = useCallback(
        (v: SurveyData) => {
            if (data) {
                updateQuestions({ serviceId: data.id, data: v.elements });
            }
        },
        [data, updateQuestions]
    );

    useEffect(() => {
        if (!isFetching && !data) {
            navigate('/service', { replace: true });
        }
    }, [data, isFetching, navigate]);

    if (isFetching || !data) {
        return null;
    }

    return (
        <MainCard
            title={data.name}
            secondary={
                <Button size="small" disableElevation onClick={() => navigate(-1)}>
                    <ChevronLeftOutlinedIcon />
                    Назад
                </Button>
            }
            contentSX={{ p: { xs: 1.5, sm: 3 }, height: 'calc(100% - 72px)' }}
            sx={{ height: '100%' }}
        >
            <Tabs value={value} onChange={handleTabsChange}>
                <Tab label="Детали" />
                <Tab label="Вопросы клиенту" />
            </Tabs>
            <Box sx={{ height: 'calc(100% - 50px)' }}>
                <TabPanel value={value} index={0} prefix="form">
                    <ServiceForm service={data} update={handleUpdate} />
                </TabPanel>
                <TabPanel value={value} index={1} prefix="survey" fullHeight>
                    <ServiceSurvey
                        data={data.widget_questions ? { elements: data.widget_questions ?? [] } : null}
                        onSaveData={onUpdateSurvey}
                    />
                </TabPanel>
            </Box>
        </MainCard>
    );
};

export default ServiceEdit;
