import * as Yup from 'yup';
import { isValidPhone } from '../../utils/phone-helpers';
import { IEmployeeLocation, IGroupedCustomShift } from '../../models/IEmployee';
import { useMemo } from 'react';
import serviceAPI from '../../services/ServiceService';
import { PaymentType } from '../../models/IService';
// import ShiftScheme from '../../ui-component/form/schemes/shift-scheme';

export type EmployeeSubFormValueType = Pick<IEmployeeLocation['pivot'], 'use_location_schedule' | 'schedule' | 'is_shifts_enabled'> & {
    shifts: IGroupedCustomShift[];
};

const LocationShiftScheme = Yup.object().shape({
    id: Yup.number().required(),
    pivot: Yup.object()
        .shape({
            schedule: Yup.mixed(),
            is_shifts_enabled: Yup.boolean(),
            use_location_schedule: Yup.boolean(),
            shifts: Yup.array().nullable()
        })
        .required()
});

const useEmployeeFormSchema = (isEdit: boolean) => {
    const services = serviceAPI.useFetchAllServicesQuery({});
    const serviceOptions = services?.data?.data;

    const employeeServiceSchema = useMemo(
        () =>
            Yup.object().shape({
                id: Yup.number().integer().positive().required(),
                has_price_override: Yup.boolean(),
                has_duration_override: Yup.boolean(),
                has_commission_override: Yup.boolean(),
                price: Yup.mixed()
                    .when('has_price_override', {
                        is: true,
                        then: Yup.number()
                            .typeError('Стоимость должна быть числом.')
                            .required('Введите стоимость.')
                            // eslint-disable-next-line func-names
                            .test('price', '', function (v) {
                                const { path, parent, createError } = this;
                                const linkedService = serviceOptions?.find((service) => service.id === parent.id);
                                const minimalValue = linkedService?.payment_type === PaymentType.Free ? 0 : 0.01;
                                const valueToCompare = v !== undefined ? parseFloat(String(v)) : 0;
                                const isValid = valueToCompare >= minimalValue;

                                return (
                                    isValid ||
                                    createError({
                                        path,
                                        message: `Стоимость должна быть больше ${minimalValue}.`
                                    })
                                );
                            })
                            .test('price', 'Стоимость не может быть меньше депозита.', (v, ctx) => {
                                const linkedService = serviceOptions?.find((service) => service.id === ctx.parent.id);
                                if (linkedService?.payment_type === PaymentType.Prepaid && linkedService?.prepay) {
                                    return (
                                        (v ? parseFloat(String(v)) : 0) >=
                                        (linkedService.prepay ? parseFloat(String(linkedService.prepay)) : 0)
                                    );
                                }

                                return true;
                            })
                            .label('Стоимость')
                    })
                    .nullable(true)
                    .transform((v) => (v === '' ? null : v))
                    .label('Стоимость'),
                duration: Yup.mixed().when('has_duration_override', {
                    is: true,
                    then: Yup.number().required().min(10).max(600).label('Длительность')
                }),
                commission_type: Yup.mixed().when('has_commission_override', {
                    is: true,
                    then: Yup.string().trim().required('Укажите тип комиссии.').oneOf(['fixed', 'percent'], 'Укажите тип комиссии.')
                }),
                commission_amount: Yup.mixed().when('has_commission_override', {
                    is: true,
                    then: Yup.number()
                        .typeError('Введите размер комиссии.')
                        .required('Введите размер комиссии.')
                        .min(0, 'Введите размер комиссии.')
                        .test('commission_amount', 'Размер комиссии не может быть больше 100', (v, ctx) => {
                            if (ctx.parent.commission_type === 'percent' && !!v) {
                                return v <= 100;
                            }

                            return true;
                        })
                })
            }),
        [serviceOptions]
    );

    const employeeUserSchema = useMemo(() => {
        if (isEdit) {
            return Yup.object().shape({
                firstname: Yup.mixed(),
                lastname: Yup.mixed(),
                email: Yup.mixed(),
                phone: Yup.mixed()
            });
        }

        return Yup.object().shape({
            firstname: Yup.string().max(255, 'Имя не может быть более 255 символов.').required('Введите имя.'),
            lastname: Yup.string().max(255, 'Фамилия не может быть более 255 символов.').required('Введите фамилию.'),
            email: Yup.string().max(255, 'Email не может быть более 255 символов.').required('Введите email.').email('Неверный email.'),
            phone: Yup.string()
                .nullable()
                .test({
                    name: 'phone',
                    test: (v) => (v ? isValidPhone(v) : true),
                    message: 'Неверный номер телефона'
                })
        });
    }, [isEdit]);

    return useMemo(
        () =>
            Yup.object().shape({
                user: employeeUserSchema,
                role: Yup.string().required('Укажите роль'),
                profession_title: Yup.string().nullable().max(255, 'Должность не может быть более 255 символов.'),
                self_book: Yup.boolean(),
                locations: Yup.array().when('self_book', {
                    is: true,
                    then: Yup.array().min(1, 'Выберите хотя бы одну локацию.'),
                    otherwise: Yup.array().nullable()
                }),
                location_shifts: Yup.array().of(LocationShiftScheme),
                services: Yup.array().when('self_book', {
                    is: true,
                    then: Yup.array().of(employeeServiceSchema).min(1, 'Выберите хотя бы одну услугу.'),
                    otherwise: Yup.array().nullable()
                }),
                schedule: Yup.mixed(),
                settings: Yup.object().shape({
                    widget: Yup.object()
                        .shape({
                            accounting_google_events: Yup.boolean().nullable()
                        })
                        .nullable(),
                    integrations: Yup.object()
                        .shape({
                            use_provider_payment_details: Yup.boolean()
                        })
                        .notRequired()
                        .nullable()
                })
            }),
        [employeeServiceSchema, employeeUserSchema]
    );
};

export default useEmployeeFormSchema;
