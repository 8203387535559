import React, { useCallback, useEffect, useState } from 'react';
import MainCard from '../../ui-component/cards/MainCard';
import {
    CardActions,
    CardContent,
    Divider,
    FormControl,
    FormHelperText,
    Grid,
    InputAdornment,
    Link,
    Theme,
    Typography,
    useMediaQuery,
    useTheme
} from '@material-ui/core';
import TwoColumnsSwitch from '../../ui-component/TwoColumnsSwitch';
import OptimizedNumberField from '../../ui-component/optimized-text-fields/OptimizedNumberField';
import { ICompany, ICompanySettings, MeetingProvidersType } from '../../models/ICompany';
import InputLabel from '../../ui-component/extended/Form/InputLabel';
import OptimizedTextField from '../../ui-component/optimized-text-fields/OptimizedTextField';
import SaveOutlined from '@material-ui/icons/SaveOutlined';
import { LoadingButton } from '@mui/lab';
import companyAPI from '../../services/CompanyService';
import useShowSnackbar from '../../hooks/useShowSnackbar';
import { SnackBarTypes } from '../../store/snackbarReducer';
import { useNavigate } from 'react-router';
import * as Yup from 'yup';
import InfoTooltip from '../../ui-component/InfoTooltip';
import LabelWithInfo from '../../ui-component/LabelWithInfo';
import ReputationManagement from './ReputationManagement';
import CCProcessors from '../../ui-component/form/CCProcessors';
import useAuth from '../../hooks/useAuth';
import { MenuItem, Stack, Tab, Tabs, TextField } from '@mui/material';
import TabPanel, { a11yProps } from '../../ui-component/TabPanel';
import NotificationsIcon from '@material-ui/icons/Notifications';
import PaymentIcon from '@material-ui/icons/Payment';
import HomeIcon from '@material-ui/icons/Home';
import CloudDoneIcon from '@material-ui/icons/CloudDone';
import GroupIcon from '@material-ui/icons/Group';
import WebhooksSettingsTable from './webhooks/WebhooksSettingsTable';
import ApiKeysList from './api-keys/ApiKeysList';
import useExtendedFormik from '../../hooks/useExtendedFormik';
import SettingsFormDelayRow from './SettingsFormDelayRow';
import salesTaxSchema from '../../ui-component/form/schemes/sales-tax-schema';
import CommonFormRow from '../../ui-component/form/CommonFormRow';
import TaxTreatmentSelect from '../../ui-component/form/TaxTreatmentSelect';

interface SettingsFormProps {
    company: ICompany;
}

const SettingsForm = ({ company }: SettingsFormProps) => {
    const { checkAuthentication } = useAuth();
    const [updateCompanySettings, { isLoading }] = companyAPI.useUpdateCompanySettingsMutation();
    const { showSnackbar } = useShowSnackbar();
    const navigate = useNavigate();
    const [value, setValue] = useState(0);
    const handleTabsChange = (_event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const theme = useTheme();
    const isMobile = useMediaQuery((themeParam: Theme) => themeParam.breakpoints.down('sm'));

    const {
        handleSubmit,
        values,
        setFieldValue,
        handleChange,
        handleBlur,
        touched,
        errors,
        setFieldError
    } = useExtendedFormik<ICompanySettings>({
        enableReinitialize: true,
        validateOnChange: true,
        validateOnBlur: false,
        initialValues: {
            ...company.settings,
            sales_tax: {
                ...company.settings.sales_tax,
                use_for_services: Boolean(company.settings.sales_tax.use_for_services),
                use_for_products: Boolean(company.settings.sales_tax.use_for_products),
                included_in_price: Boolean(company.settings.sales_tax.included_in_price)
            }
        },
        validationSchema: Yup.object().shape({
            notifications: Yup.object().shape({
                enabled: Yup.boolean().nullable(),
                immediately_sms_notify: Yup.boolean().nullable(),
                delay: Yup.number().required().min(0).max(60).label('Задержка уведомлений'),
                customer: Yup.object().shape({
                    appointment_deposit_request_reminder: Yup.number().required().min(1).label('Appointment Deposit Request'),
                    appointment_invite_reminder: Yup.object().shape({
                        first: Yup.number().required().min(1).label('Appointment Request Reminder'),
                        final: Yup.number().required().min(1).label('Appointment Request Final')
                    }),
                    appointment_reminder: Yup.object().shape({
                        virtual_service: Yup.number().required().min(1).label('Virtual Service Reminder'),
                        service: Yup.number().required().min(1).label('Service Reminder')
                    }),
                    rebook_reminder_follow_up: Yup.number().required().min(1).label('Rebook Reminder Follow-up Request')
                })
            }),
            appointments: Yup.object().shape({
                no_show_deposit: Yup.object().shape({
                    enabled: Yup.boolean().required('Required field'),
                    percent: Yup.number().when('enabled', {
                        is: true,
                        then: Yup.number().min(1, 'Минимальное значение 1').max(100, 'Максимальное значение 100'),
                        otherwise: Yup.number().nullable()
                    })
                }),
                use_contact_privacy: Yup.boolean(),
                use_products: Yup.boolean()
            }),
            integrations: Yup.object().shape({
                reputation_management: Yup.string().required('Reputation management is required'),
                gradeus: Yup.object().shape({
                    api_key: Yup.string().nullable(),
                    profile_id: Yup.string().nullable()
                }),
                reviewshake: Yup.object().shape({
                    api_key: Yup.string().nullable(),
                    custom_domain: Yup.string().nullable(),
                    subdomain: Yup.string().nullable(),
                    campaign: Yup.string().nullable(),
                    client: Yup.string().nullable(),
                    location_slug: Yup.string().nullable()
                }),
                paypal: Yup.object().shape({
                    client_id: Yup.string().nullable(),
                    client_secret: Yup.string().nullable()
                }),
                cc_processor: Yup.string().required('CC processor is required'),
                authorize_net: Yup.object().shape({
                    api_login_id: Yup.string().nullable(),
                    transaction_key: Yup.string().nullable()
                }),
                stripe: Yup.object().shape({
                    secret_key: Yup.string().nullable(),
                    publishable_key: Yup.string().nullable()
                }),
                twilio: Yup.object().shape({
                    auth_token: Yup.string().nullable(),
                    account_sid: Yup.string().nullable()
                }),
                meetings: Yup.string().trim().required().oneOf(['whereby', 'zoom']).label('Meetings'),
                zoom: Yup.mixed().when('meetings', {
                    is: 'zoom',
                    then: Yup.object().shape({
                        account_id: Yup.string().trim().nullable().required().label('Zoom Account Id'),
                        client_id: Yup.string().trim().nullable().required().label('Zoom Client Id'),
                        client_secret: Yup.string().trim().nullable().required().label('Zoom Client Secret')
                    }),
                    otherwise: Yup.mixed().nullable().notRequired()
                })
            }),
            sales_tax: salesTaxSchema
        }),
        onSubmit: (formData) => {
            updateCompanySettings({ settings: formData, companyId: company.id })
                .unwrap()
                .then(() => {
                    checkAuthentication();
                    showSnackbar({
                        message: 'Настройки успешно сохранены',
                        alertSeverity: SnackBarTypes.Success
                    });
                    // navigate('/');
                })
                .catch((e) => {
                    if (e.errors) {
                        Object.keys(e.errors).forEach((key) => {
                            const fieldName = key.replace('settings.', '');
                            setFieldError(fieldName, e.errors[key]);
                            // setFieldTouched(fieldName, true);
                        });
                    }
                    showSnackbar({
                        message: e.data || 'Настройки не были сохранены',
                        alertSeverity: SnackBarTypes.Error
                    });
                });
        }
    });

    const getZoomIntegrationsError = useCallback(
        (field: 'account_id' | 'client_id' | 'client_secret'): string | undefined => {
            const isTouched = typeof touched.integrations?.zoom === 'object' ? Boolean(touched.integrations?.zoom?.[field]) : false;
            const errMessage = typeof errors.integrations?.zoom === 'object' ? errors.integrations?.zoom?.[field] : undefined;

            return isTouched ? errMessage : undefined;
        },
        [touched, errors]
    );

    const hasErrorsInTab = useCallback(
        (tab: 'appointments' | 'payments' | 'integrations') => {
            const { appointments, integrations } = errors;
            switch (tab) {
                case 'appointments': {
                    return !!appointments;
                }

                case 'integrations': {
                    return (
                        !!integrations?.gradeus ||
                        !!integrations?.reviewshake ||
                        !!integrations?.reputation_management ||
                        !!integrations?.twilio ||
                        !!integrations?.meetings ||
                        (['account_id', 'client_id', 'client_secret'] as const).some((field) => !!getZoomIntegrationsError(field))
                    );
                }

                case 'payments': {
                    return (
                        !!integrations?.authorize_net || !!integrations?.paypal || !!integrations?.cc_processor || !!integrations?.stripe
                    );
                }

                default: {
                    return false;
                }
            }
        },
        [errors, getZoomIntegrationsError]
    );

    const getTabLabel = useCallback((label: string) => (isMobile ? undefined : label), [isMobile]);

    const handleDelayFieldChange = useCallback(
        (fieldName: string, fieldValue: unknown) => {
            setFieldValue(fieldName, fieldValue);
        },
        [setFieldValue]
    );

    useEffect(() => {
        if (values.appointments.waitlist.enabled) {
            setFieldValue('appointments.waitlist.any_employee', Boolean(values.appointments.waitlist.any_employee));
        }
    }, [values.appointments.waitlist.enabled, values.appointments.waitlist.any_employee, setFieldValue]);

    return (
        <MainCard title="Общие настройки" content={false}>
            <form onSubmit={handleSubmit} noValidate>
                <CardContent>
                    <Tabs
                        sx={{
                            mb: 3,
                            '& .MuiTab-root.Mui-error': { color: theme.palette.error.main },
                            '& .MuiTab-root.MuiButtonBase-root': isMobile
                                ? {
                                      p: 1,
                                      minWidth: '50px',
                                      flexGrow: 1
                                  }
                                : undefined
                        }}
                        value={value}
                        onChange={handleTabsChange}
                    >
                        <Tab icon={<NotificationsIcon />} aria-label="Уведомления" label={getTabLabel('Уведомления')} />
                        <Tab
                            icon={<GroupIcon />}
                            aria-label="Записи"
                            label={getTabLabel('Записи')}
                            className={hasErrorsInTab('appointments') ? 'Mui-error' : ''}
                        />
                        <Tab
                            icon={<PaymentIcon />}
                            aria-label="Платежи"
                            label={getTabLabel('Платежи')}
                            className={hasErrorsInTab('payments') ? 'Mui-error' : ''}
                        />
                        <Tab
                            icon={<CloudDoneIcon />}
                            aria-label="Интеграции"
                            label={getTabLabel('Интеграции')}
                            className={hasErrorsInTab('integrations') ? 'Mui-error' : ''}
                        />
                        <Tab icon={<HomeIcon />} aria-label="Терминал" label={getTabLabel('Терминал')} />
                    </Tabs>

                    {/* Notifications tab */}
                    <TabPanel value={value} index={0} prefix="settings" {...a11yProps(0, 'settings')}>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={12}>
                                <Typography variant="h4" color="primary">
                                    Уведомления
                                </Typography>
                            </Grid>
                            <TwoColumnsSwitch
                                fieldName="notifications.enabled"
                                setFieldValue={setFieldValue}
                                value={values.notifications.enabled}
                                label="Включить уведомления"
                                labelDecoration={<InfoTooltip text="Глобально вкл/выкл все уведомления системы по email и SMS." />}
                            />
                            <TwoColumnsSwitch
                                fieldName="notifications.immediately_sms_notify"
                                setFieldValue={setFieldValue}
                                value={values.notifications.immediately_sms_notify}
                                label="SMS уведомления сотрудникам"
                                labelDecoration={
                                    <InfoTooltip text="Сотрудник получает SMS уведомления при создании записи с его участием. Можно отключить для экономии SMS трафика." />
                                }
                            />
                            <TwoColumnsSwitch
                                fieldName="appointments.completed_notify_customers"
                                setFieldValue={setFieldValue}
                                value={values.appointments.completed_notify_customers}
                                label="Уведомления клиенту о завершении"
                                labelDecoration={<InfoTooltip text="Клиент получает уведомление об успешном завершении записи." />}
                            />

                            <SettingsFormDelayRow
                                label="Задержка уведомлений"
                                fieldKey="notifications.delay"
                                value={values.notifications.delay}
                                error={errors.notifications?.delay}
                                onBlur={handleBlur}
                                onChange={handleDelayFieldChange}
                                scale="Минуты"
                                infoText="Задержка уведомлений о создании и изменении записи. Если в течение времени задержки совершено несколько изменений, уведомление будет содержать только последние изменения."
                            />

                            <SettingsFormDelayRow
                                label="Напоминание о записи"
                                fieldKey="notifications.customer.appointment_reminder.service"
                                value={values.notifications.customer.appointment_reminder.service}
                                error={errors.notifications?.customer?.appointment_reminder?.service}
                                onChange={handleDelayFieldChange}
                                onBlur={handleBlur}
                                scale="Часы"
                                infoText="Напоминание клиенту о предстоящей записи, в часах до времени записи."
                            />

                            <SettingsFormDelayRow
                                label="Напоминание о виртуальной записи"
                                fieldKey="notifications.customer.appointment_reminder.virtual_service"
                                value={values.notifications.customer.appointment_reminder.virtual_service}
                                error={errors.notifications?.customer?.appointment_reminder?.virtual_service}
                                onChange={handleDelayFieldChange}
                                onBlur={handleBlur}
                                scale="Часы"
                                infoText="Напоминание клиенту о предстоящей виртуальной записи, в часах до времени записи."
                            />

                            <SettingsFormDelayRow
                                label="Напоминание о приглашении"
                                fieldKey="notifications.customer.appointment_invite_reminder.first"
                                value={values.notifications.customer.appointment_invite_reminder.first}
                                error={errors.notifications?.customer?.appointment_invite_reminder?.first}
                                onChange={handleDelayFieldChange}
                                onBlur={handleBlur}
                                scale="Часы"
                                infoText="Первое напоминание о приглашении записаться. В часах после отправки приглашения."
                            />

                            <SettingsFormDelayRow
                                label="Второе напоминание о приглашении"
                                fieldKey="notifications.customer.appointment_invite_reminder.final"
                                value={values.notifications.customer.appointment_invite_reminder.final}
                                error={errors.notifications?.customer?.appointment_invite_reminder?.final}
                                onChange={handleDelayFieldChange}
                                onBlur={handleBlur}
                                scale="Часы"
                                infoText="Второе напоминание о приглашении записаться. В часах после отправки приглашения."
                            />

                            <SettingsFormDelayRow
                                label="Напоминание о запросе депозита"
                                fieldKey="notifications.customer.appointment_deposit_request_reminder"
                                value={values.notifications.customer.appointment_deposit_request_reminder}
                                error={errors.notifications?.customer?.appointment_deposit_request_reminder}
                                onChange={handleDelayFieldChange}
                                onBlur={handleBlur}
                                scale="Часы"
                                infoText="В часах после отправки депозита."
                            />

                            <SettingsFormDelayRow
                                label="Напоминание о повторной записи"
                                fieldKey="notifications.customer.rebook_reminder_follow_up"
                                value={values.notifications.customer.rebook_reminder_follow_up}
                                error={errors.notifications?.customer?.rebook_reminder_follow_up}
                                onChange={handleDelayFieldChange}
                                onBlur={handleBlur}
                                scale="Часы"
                                infoText="Повторное напоминание, в часах после отправки первого."
                            />
                        </Grid>
                    </TabPanel>

                    {/* Appointments tab */}
                    <TabPanel value={value} index={1} prefix="settings" {...a11yProps(1, 'settings')}>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={12}>
                                <Typography variant="h4" color="primary">
                                    Записи
                                </Typography>
                            </Grid>
                            <TwoColumnsSwitch
                                fieldName="appointments.autocomplete.enabled"
                                setFieldValue={setFieldValue}
                                value={values.appointments.autocomplete.enabled}
                                label="Автоматически завершать записи"
                                labelDecoration={
                                    <InfoTooltip text="Если включено, записи автоматически завершаются после истечения указанного ниже промежутка времени. При автоматическом закрытии в запись автоматически добавляется платеж равный сумме услуги. Это может быть удобно, если ваш бизнес берет фиксированную оплату за услуги." />
                                }
                            />
                            {values.appointments.autocomplete.enabled && (
                                <>
                                    <Grid item xs={12} sm={3} lg={4}>
                                        <LabelWithInfo
                                            label="Интервал завершения, в часах"
                                            infoText="Количество времени, по истечении которого записи автоматически завершаются."
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={9} lg={6}>
                                        <FormControl
                                            fullWidth
                                            error={Boolean(
                                                touched.appointments?.autocomplete?.interval && errors.appointments?.autocomplete?.interval
                                            )}
                                        >
                                            <OptimizedNumberField
                                                id="appointments.autocomplete.interval"
                                                name="appointments.autocomplete.interval"
                                                value={values.appointments.autocomplete.interval}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                InputProps={{
                                                    startAdornment: (
                                                        <>
                                                            <InputAdornment position="start">Часы</InputAdornment>
                                                            <Divider sx={{ height: 28, m: 0.5, mr: 1.5 }} orientation="vertical" />
                                                        </>
                                                    )
                                                }}
                                            />
                                            {touched.appointments?.autocomplete?.interval && errors.appointments?.autocomplete?.interval && (
                                                <FormHelperText error id="error-appointment-autocomplete-interval">
                                                    {errors.appointments?.autocomplete?.interval}
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    </Grid>
                                </>
                            )}
                            <TwoColumnsSwitch
                                fieldName="appointments.no_show_deposit.enabled"
                                setFieldValue={setFieldValue}
                                value={values.appointments.no_show_deposit.enabled}
                                label="Включить депозит за неявку"
                                labelDecoration={
                                    <InfoTooltip text="Если вкл, клиенты, которые не пришли на предыдущую запись, будут вынуждены оплатить депозит при следующей онлайн брони. Размер депозита указывается ниже." />
                                }
                            />

                            {values.appointments.no_show_deposit.enabled && (
                                <>
                                    <Grid item xs={12} sm={3} lg={4}>
                                        <InputLabel horizontal>Размер депозита</InputLabel>
                                    </Grid>
                                    <Grid item xs={12} sm={9} lg={6}>
                                        <FormControl
                                            fullWidth
                                            error={Boolean(
                                                touched.appointments?.no_show_deposit?.percent &&
                                                    errors.appointments?.no_show_deposit?.percent
                                            )}
                                        >
                                            <OptimizedNumberField
                                                id="appointments.no_show_deposit.percent"
                                                name="appointments.no_show_deposit.percent"
                                                value={values.appointments.no_show_deposit.percent}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                InputProps={{
                                                    startAdornment: (
                                                        <>
                                                            <InputAdornment position="start">%</InputAdornment>
                                                            <Divider sx={{ height: 28, m: 0.5, mr: 1.5 }} orientation="vertical" />
                                                        </>
                                                    )
                                                }}
                                            />
                                            {touched.appointments?.no_show_deposit?.percent &&
                                                errors.appointments?.no_show_deposit?.percent && (
                                                    <FormHelperText error id="error-no-show-percent">
                                                        {errors.appointments?.no_show_deposit?.percent}
                                                    </FormHelperText>
                                                )}
                                        </FormControl>
                                    </Grid>
                                </>
                            )}

                            <TwoColumnsSwitch
                                fieldName="appointments.use_modal_view"
                                setFieldValue={setFieldValue}
                                value={values.appointments.use_modal_view}
                                label="Показывать записи в модалке"
                            />

                            <TwoColumnsSwitch
                                fieldName="appointments.use_contact_privacy"
                                setFieldValue={setFieldValue}
                                value={values.appointments.use_contact_privacy}
                                label="Защита данных клиентов"
                            />

                            <TwoColumnsSwitch
                                fieldName="widget.use_multiservices"
                                setFieldValue={setFieldValue}
                                label="Несколько услуг в записи"
                                value={Boolean(values?.widget?.use_multiservices)}
                            />

                            <TwoColumnsSwitch
                                fieldName="appointments.use_products"
                                setFieldValue={setFieldValue}
                                label="Продукты"
                                value={Boolean(values?.appointments?.use_products)}
                            />

                            <TwoColumnsSwitch
                                fieldName="appointments.waitlist.enabled"
                                setFieldValue={setFieldValue}
                                value={values.appointments.waitlist.enabled}
                                label="Список ожидания"
                            />

                            {values.appointments.waitlist.enabled ? (
                                <TwoColumnsSwitch
                                    fieldName="appointments.waitlist.any_employee"
                                    setFieldValue={setFieldValue}
                                    value={values.appointments.waitlist.any_employee}
                                    label="Разрешить список ожидания с любым исполнителем"
                                />
                            ) : null}
                        </Grid>
                    </TabPanel>

                    {/* Payments Tab */}
                    <TabPanel value={value} index={2} prefix="settings" {...a11yProps(2, 'settings')}>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={12}>
                                <Typography variant="h4" color="primary">
                                    Платежи
                                </Typography>
                            </Grid>
                            <CCProcessors
                                values={values.integrations}
                                errors={errors.integrations}
                                touched={touched.integrations}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                            />

                            <Grid item xs={12}>
                                <Divider />
                            </Grid>

                            <TwoColumnsSwitch
                                fieldName="enable_commissions"
                                setFieldValue={setFieldValue}
                                value={values.enable_commissions}
                                label="Включить комиссии"
                                labelDecoration={
                                    <InfoTooltip text="Если вкл, можно будет указать размер комиссии от стоимости оказанных услуг для каждого сотрудника. Также появится возможность скачать отчет по комиссиям на странице Отчеты." />
                                }
                            />

                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                        </Grid>
                    </TabPanel>

                    {/* Integrations Tab */}
                    <TabPanel value={value} index={3} prefix="settings" {...a11yProps(3, 'settings')}>
                        <Stack spacing={2} divider={<Divider />}>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={12}>
                                    <Typography variant="h4" color="primary">
                                        Интеграции
                                    </Typography>
                                </Grid>
                                <ReputationManagement
                                    values={values}
                                    errors={errors}
                                    touched={touched}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                />
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                                {company.is_twilio_enabled && (
                                    <>
                                        <Grid item xs={12} sm={3} lg={4}>
                                            <LabelWithInfo
                                                label="Twilio Auth Token"
                                                infoText="Please fill out you Twilio account credentials to have the system send SMS notifications to customers and staff."
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={9} lg={6}>
                                            <FormControl fullWidth>
                                                <OptimizedTextField
                                                    id="integrations.twilio.auth_token"
                                                    name="integrations.twilio.auth_token"
                                                    placeholder="Auth Token"
                                                    value={values?.integrations?.twilio?.auth_token}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    autocomplete="off"
                                                    variant="outlined"
                                                />
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} sm={3} lg={4}>
                                            <InputLabel horizontal>Twilio Account SID</InputLabel>
                                        </Grid>
                                        <Grid item xs={12} sm={9} lg={6}>
                                            <FormControl fullWidth>
                                                <OptimizedTextField
                                                    id="integrations.twilio.account_sid"
                                                    name="integrations.twilio.account_sid"
                                                    placeholder="Account SID"
                                                    value={values?.integrations?.twilio?.account_sid}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    autocomplete="off"
                                                    variant="outlined"
                                                />
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Divider />
                                        </Grid>
                                    </>
                                )}

                                <Grid item xs={12}>
                                    <Typography variant="h4" color="primary">
                                        Meetings
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={3} lg={4}>
                                    <InputLabel horizontal>Meetings Provider</InputLabel>
                                </Grid>
                                <Grid item xs={12} sm={9} lg={6}>
                                    <TextField
                                        fullWidth
                                        id="integrations.meetings"
                                        name="integrations.meetings"
                                        value={values.integrations.meetings}
                                        onChange={(e) => {
                                            if (
                                                e.target.value === MeetingProvidersType.Zoom ||
                                                e.target.value === MeetingProvidersType.Whereby
                                            ) {
                                                setFieldValue('integrations.meetings', e.target.value);
                                            }
                                        }}
                                        select
                                        onBlur={handleBlur}
                                        error={!!errors.integrations?.meetings && !!touched.integrations?.meetings}
                                        helperText={touched.integrations?.meetings ? errors.integrations?.meetings : undefined}
                                    >
                                        <MenuItem value={MeetingProvidersType.Whereby}>Whereby</MenuItem>
                                        <MenuItem value={MeetingProvidersType.Zoom}>Zoom</MenuItem>
                                    </TextField>
                                </Grid>

                                {values.integrations.meetings === MeetingProvidersType.Zoom ? (
                                    <>
                                        <Grid item xs={12} sm={3} lg={4}>
                                            <InputLabel horizontal>Zoom Account Id</InputLabel>
                                        </Grid>
                                        <Grid item xs={12} sm={9} lg={6}>
                                            <TextField
                                                fullWidth
                                                id="integrations.zoom.account_id"
                                                name="integrations.zoom.account_id"
                                                value={values.integrations.zoom?.account_id ?? ''}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={!!getZoomIntegrationsError('account_id')}
                                                helperText={getZoomIntegrationsError('account_id')}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={3} lg={4}>
                                            <InputLabel horizontal>Zoom Client Id</InputLabel>
                                        </Grid>
                                        <Grid item xs={12} sm={9} lg={6}>
                                            <TextField
                                                fullWidth
                                                id="integrations.zoom.client_id"
                                                name="integrations.zoom.client_id"
                                                value={values.integrations.zoom?.client_id ?? ''}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={!!getZoomIntegrationsError('client_id')}
                                                helperText={getZoomIntegrationsError('client_id')}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={3} lg={4}>
                                            <InputLabel horizontal>Zoom Client Secret</InputLabel>
                                        </Grid>
                                        <Grid item xs={12} sm={9} lg={6}>
                                            <TextField
                                                fullWidth
                                                id="integrations.zoom.client_secret"
                                                name="integrations.zoom.client_secret"
                                                value={values.integrations.zoom?.client_secret ?? ''}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={!!getZoomIntegrationsError('client_secret')}
                                                helperText={getZoomIntegrationsError('client_secret')}
                                            />
                                        </Grid>
                                    </>
                                ) : null}
                            </Grid>
                        </Stack>
                    </TabPanel>

                    {/* Kiosk Tab */}
                    <TabPanel value={value} index={4} prefix="settings" {...a11yProps(4, 'settings')}>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={12}>
                                <Typography variant="h4" color="primary" display="flex" alignItems="center">
                                    Терминал
                                    <InfoTooltip text="Используя эти ссылки на терминале самообслуживания, клиенты смогут сами бронировать услуги и увемолять мастера о своем прибытии по записи (мастер получит SMS уведомление)." />
                                </Typography>
                            </Grid>
                            {company.locations?.map((location) => (
                                <React.Fragment key={location.id}>
                                    <Grid item xs={12} sm={3} lg={4}>
                                        {location.name}
                                    </Grid>
                                    <Grid item xs={12} sm={9} lg={6}>
                                        <Link href={location.kioskUrlShort} target="_blank">
                                            {location.kioskUrlShort}
                                        </Link>
                                    </Grid>
                                </React.Fragment>
                            ))}
                        </Grid>
                    </TabPanel>
                </CardContent>
                <Divider />
                <CardActions>
                    <LoadingButton
                        loading={isLoading}
                        loadingPosition="start"
                        type="submit"
                        color="primary"
                        startIcon={<SaveOutlined />}
                        variant="contained"
                    >
                        Сохранить
                    </LoadingButton>
                </CardActions>
            </form>
        </MainCard>
    );
};

export default SettingsForm;
