import { IService, ServiceLocationType } from '../../models/IService';
import { useMemo } from 'react';
import { initServiceSchedule, min_service_reschedule_interval } from '../../store/constant';

const useServiceDefaults = (service: IService) => {
    const schedule = service.schedule || initServiceSchedule;

    return useMemo(
        () => ({
            ...service,
            schedule: [...schedule].sort((a, b) => (a.id === 0 && b.id === 0 ? 0 : a.id - b.id)),
            price_from: service.price_from ?? false,
            is_reschedule_enabled: service.is_reschedule_enabled ?? true,
            is_waiver_enabled: service.is_waiver_enabled ?? false,
            rescheduling_interval: service.rescheduling_interval ?? min_service_reschedule_interval,
            location_type: service.location_type ?? ServiceLocationType.Business,
            is_private: service.is_private ?? false,
            waivers: service.waivers?.length ? service.waivers[0].id : null,
            staff_autoassign: service?.staff_autoassign ?? false,
            waivers_sign_type: service.waivers?.length ? service.waivers[0].sign_type : undefined,
            use_materials: Boolean(service.use_materials),
            materials: service.materials || {
                name: '',
                units: '',
                price_per_unit: null
            },
            use_rebook_reminder: service.use_rebook_reminder,
            use_service_policy: {
                enabled: service.use_service_policy.enabled,
                policy_text: service.use_service_policy.policy_text ?? '',
                policy_title: service.use_service_policy.policy_title ?? ''
            },
            requires_confirmation: {
                ...service.requires_confirmation,
                from_employee: !!service.requires_confirmation?.from_employee
            }
        }),
        [schedule, service]
    );
};

export default useServiceDefaults;
