import { useParams } from 'react-router';
import waiverAPI from '../../services/WaiverService';
import WaiverCreator from './WaiverCreator';
import { Button, CircularProgress, Stack } from '@material-ui/core';
import ChevronLeftOutlinedIcon from '@material-ui/icons/ChevronLeftOutlined';
import MainCard from 'ui-component/cards/MainCard';
import { useNavigate } from 'react-router-dom';

const WaiverEdit = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const { data, isLoading } = waiverAPI.useGetWaiverQuery(id || '', {
        refetchOnMountOrArgChange: true
    });

    return (
        <MainCard
            title="Form Builder"
            secondary={
                <Button size="small" disableElevation onClick={() => navigate(-1)}>
                    <ChevronLeftOutlinedIcon />
                    Назад
                </Button>
            }
            contentSX={{ p: { xs: 1.5, sm: 3 } }}
        >
            {!isLoading && data ? (
                <WaiverCreator initData={data} />
            ) : (
                <Stack justifyContent="center" direction="row" sx={{ mx: 'auto', mt: 1, width: 200 }}>
                    <CircularProgress />
                </Stack>
            )}
        </MainCard>
    );
};

export default WaiverEdit;
