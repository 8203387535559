import { useCallback } from 'react';
import { Box, Button, DialogActions, IconButton, Tooltip } from '@mui/material';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { useShiftPickerContext } from './shift-picker-context';

const ShiftPickerActionsBar = () => {
    const ctx = useShiftPickerContext();
    const prevEntry = ctx?.prevEntry;
    const nextEntry = ctx?.nextEntry;
    const handleMonthChange = ctx?.handleMonthChange;
    const onClose = ctx?.onClose;

    const handleNext = useCallback(() => (handleMonthChange && nextEntry ? handleMonthChange(nextEntry) : undefined), [
        nextEntry,
        handleMonthChange
    ]);
    const handlePrev = useCallback(() => (handleMonthChange && prevEntry ? handleMonthChange(prevEntry) : undefined), [
        prevEntry,
        handleMonthChange
    ]);

    return (
        <DialogActions sx={{ gridRow: 3, gridColumn: 'span 2' }}>
            <Button onClick={onClose}>Выбрать</Button>
            <Box ml="auto">
                {!!prevEntry && (
                    <Tooltip title="To Previous Entry">
                        <IconButton onClick={handlePrev}>
                            <ArrowBackIcon />
                        </IconButton>
                    </Tooltip>
                )}
                {!!nextEntry && (
                    <Tooltip title="To Next Entry">
                        <IconButton onClick={handleNext}>
                            <ArrowForwardIcon />
                        </IconButton>
                    </Tooltip>
                )}
            </Box>
        </DialogActions>
    );
};

export default ShiftPickerActionsBar;
