import { Box, Tooltip } from '@mui/material';
import ContactSupportOutlined from '@material-ui/icons/ContactSupportOutlined';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { setShowSupportWidget } from '../../../store/slices/layoutSlice';
import CommonHeaderBtn from '../../../ui-component/CommonHeaderBtn';

const SupportWidget = () => {
    const dispatch = useAppDispatch();
    const { showSupportWidget } = useAppSelector((state) => state.layout);

    const handleClick = () => {
        const jdiv = document.querySelector('jdiv') as HTMLElement;
        if (!jdiv) {
            const script = document.createElement('script');
            script.src = '//code.jivo.ru/widget/9JkAIp97Jp';
            script.async = true;
            document.head.appendChild(script);
        }
        dispatch(setShowSupportWidget(!showSupportWidget));
    };

    return (
        <Tooltip title="Поддержка" placement="top" arrow>
            <Box component="span">
                <CommonHeaderBtn onClick={handleClick}>
                    <ContactSupportOutlined sx={{ fontSize: 18 }} />
                </CommonHeaderBtn>
            </Box>
        </Tooltip>
    );
};

export default SupportWidget;
