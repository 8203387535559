import WaiverCreator from './WaiverCreator';
import { Button } from '@material-ui/core';
import ChevronLeftOutlinedIcon from '@material-ui/icons/ChevronLeftOutlined';
import MainCard from 'ui-component/cards/MainCard';
import { useNavigate } from 'react-router-dom';

const WaiverCreate = () => {
    const navigate = useNavigate();

    return (
        <MainCard
            title="Form"
            secondary={
                <Button size="small" disableElevation onClick={() => navigate(-1)}>
                    <ChevronLeftOutlinedIcon />
                    Назад
                </Button>
            }
            contentSX={{ p: { xs: 1.5, sm: 3 } }}
        >
            <WaiverCreator initData={{ title: '', data: null }} />
        </MainCard>
    );
};

export default WaiverCreate;
